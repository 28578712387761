/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onNotifyEstateC = /* GraphQL */ `subscription OnNotifyEstateC {
  onNotifyEstateC {
    id
    infoName
    condominiumId
    confirmed
    status
    estateType
    name
    fullAddress
    prefecture
    city
    area
    address
    mediationKind
    currentStatus
    currentStatusFree
    traffic
    eSchool
    jSchool
    remarks
    landAreaType
    squareArea
    tsuboArea
    estatePrice
    unitPrice
    pricedAt
    priceHistory {
      price
      pricedAt
      __typename
    }
    completionSchMonth
    completionMonth
    clearingType
    cleaningMonth
    bldConditionType
    landRights
    cityPlanningType
    useDistrict
    floorAreaRatio
    bcr
    roadType
    drivewayPay
    roadDirection
    roadWidth
    water
    sewer
    gas
    groundType
    groundTypeFree
    layout
    totalArea
    structure
    structureFree
    buildingFloors
    balconyArea
    managementType
    pet
    floor
    room
    managementFee
    repairCost
    otherCost
    parking
    carsInParkingN
    parkingTakeOver
    parkingTakeOverFree
    parkingMechOrPlane
    parkingHeight
    parkingFee
    purposeB
    purposeSummaryB
    companyId
    companyName
    companyAddress
    companyTel
    companyFax
    companyUrl
    companyLicense
    companyGuaranter
    subCompanyId
    subCompanyName
    subCompanyAddress
    subCompanyTel
    subCompanyFax
    subCompanyUrl
    subCompanyLicense
    subCompanyGuaranter
    subCompany2Id
    subCompany2Name
    subCompany2Address
    subCompany2Tel
    subCompany2Fax
    subCompany2Url
    subCompany2License
    subCompany2Guaranter
    latitude
    longitude
    tileOnZoom12
    tileOnZoom14
    tileOnZoom16
    layoutImgs
    appearanceImgs
    mapImg
    waterPdf
    sewerPdf
    gasPdf
    cadastralMapPdf
    groundPdf
    url
    license
    recNo
    media
    location
    collectedAt
    floorStructure
    buildingCompany
    notFoundAt
    exactLocation
    nearlyLocation
    unsettledLocation
    isLot
    currentStatusB
    currentStatusG
    companyRecNo
    companyBranchId
    subCompanyRecNo
    completionMonthS
    condominiumName
    condominiumRecNo
    totalHouses
    manager
    seller
    estateUrl
    deletedOnSumora
    searchInfo
    owner
    createdAt
    updatedAt
    sold
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnNotifyEstateCSubscriptionVariables,
  APITypes.OnNotifyEstateCSubscription
>;
export const onCreateBatchJob = /* GraphQL */ `subscription OnCreateBatchJob($filter: ModelSubscriptionBatchJobFilterInput) {
  onCreateBatchJob(filter: $filter) {
    id
    jobName
    remarks
    status
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBatchJobSubscriptionVariables,
  APITypes.OnCreateBatchJobSubscription
>;
export const onCreateScraping = /* GraphQL */ `subscription OnCreateScraping($filter: ModelSubscriptionScrapingFilterInput) {
  onCreateScraping(filter: $filter) {
    baseUrl
    prefName
    status
    operation
    input
    startedAt
    totalCount
    updating {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    updated {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    resultKey
    endedAt
    output
    remarks
    sortKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateScrapingSubscriptionVariables,
  APITypes.OnCreateScrapingSubscription
>;
export const onUpdateScraping = /* GraphQL */ `subscription OnUpdateScraping($filter: ModelSubscriptionScrapingFilterInput) {
  onUpdateScraping(filter: $filter) {
    baseUrl
    prefName
    status
    operation
    input
    startedAt
    totalCount
    updating {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    updated {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    resultKey
    endedAt
    output
    remarks
    sortKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateScrapingSubscriptionVariables,
  APITypes.OnUpdateScrapingSubscription
>;
