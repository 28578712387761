import { AWSCredentials } from "@aws-amplify/core/dist/esm/singleton/Auth/types";
import { AuthenticatorRoute } from "@aws-amplify/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Company, User } from "API";
import { fetchAuthSession } from "aws-amplify/auth";
import { GroupEnum } from "common/enums/GroupEnum";
import { getCompany as invokeGetCompany } from "common/functions/api/companies";
import { getUser as invokeGetUser } from "common/functions/api/users";
import { getCredentialStates } from "common/functions/credentials";
import { handleError, remainTrialDays } from "common/functions/utilities";
import { UserInfo } from "common/interfaces/UserInfo";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export const useAuthHooks = () => {
  const initRef = useRef(true);

  const [user, setUser] = useState<User | undefined>(undefined);
  const [credentials, setCredentials] = useState<AWSCredentials | undefined>(
    undefined
  );
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [groups, setGroups] = useState<string[] | undefined>(undefined);
  const [eventId, setEventId] = useState<string | undefined>(undefined);

  const [company, setCompany] = useState<Company | undefined>(undefined);

  const [showConfirmSignOut, setShowConfirmSignOut] = useState<boolean>(false);
  const [showRecommendSignOut, setShowRecommendSignOut] =
    useState<boolean>(false);

  const [busy, setBusy] = useState<boolean>(false);

  const [fatalMessage, setFatalMessage] = useState<string | undefined>(
    undefined
  );
  const [forceLogoutMessage, setForceLogoutMessage] = useState<
    string | undefined
  >(undefined);

  const [logoVisible, setLogoVisible] = useState<boolean>(true);

  const { user: authUser, signOut } = useAuthenticator((context) => [
    context.user
  ]);

  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const { route } = useAuthenticator((context) => [context.route]);

  const location = useLocation();

  const isAuthenticated = useMemo(
    () => authStatus === "authenticated" && (authUser?.username ?? "") !== "",
    [authStatus, authUser?.username]
  );

  const isAgreed = useMemo(
    () => isAuthenticated && (user?.agreement ?? undefined),
    [isAuthenticated, user?.agreement]
  );

  const isReadyToUse = useMemo(
    () =>
      (isAuthenticated &&
        isAgreed &&
        groups &&
        (groups ?? []).length !== 0 &&
        (groups.includes(GroupEnum.Administrator) ||
          groups.includes(GroupEnum.Staff) ||
          groups.includes(GroupEnum.HouseMaker) ||
          groups.includes(GroupEnum.RealEstateAgency) ||
          groups.includes(GroupEnum.RealEstateAgencyFree))) ??
      false,
    [groups, isAgreed, isAuthenticated]
  );

  const showFatal = useMemo(
    () => fatalMessage !== undefined && fatalMessage !== "",
    [fatalMessage]
  );

  const showForceLogout = useMemo(
    () => forceLogoutMessage !== undefined && forceLogoutMessage !== "",
    [forceLogoutMessage]
  );

  const trialExpired = useMemo(() => {
    const remain = remainTrialDays(user?.trial, user?.trialEndAt);

    return remain === undefined ? false : remain <= 0;
  }, [user?.trial, user?.trialEndAt]);

  // エラー表示
  const setFatal = useCallback((error: string | null | undefined) => {
    window.console.error(error);
    if (
      error?.toLowerCase() === "no federated jwt" ||
      error?.toLowerCase() === "not authorized"
    ) {
      setForceLogoutMessage(
        "ログインの有効期限が切れたか、同一IDの重複ログインが検出されました。再度ログインする必要があります。"
      );
      setTimeout(() => {
        setForceLogoutMessage(undefined);
      }, 10000);
      return;
    }

    setFatalMessage(
      `${error ?? "不明なエラー"}: 詳細はコンソールを確認してください。`
    );
  }, []);

  // User取得
  const getUser = useCallback(
    async (username: string | undefined) => {
      const user = await invokeGetUser(username).catch((error) => {
        setFatal(error);
        return undefined;
      });

      setUser(user?.getUser ?? undefined);
    },
    [setFatal]
  );

  // Company取得
  const getCompany = useCallback(
    async (companyId: string | null | undefined) => {
      const company = await invokeGetCompany(companyId).catch((error) => {
        setFatal(error);
        return undefined;
      });

      setCompany(company?.getCompany ?? undefined);
    },
    [setFatal]
  );

  // signInState変化時
  const onChangeSignInState = useCallback(
    async (signInState: AuthenticatorRoute | undefined) => {
      // サインイン状態の変化時
      setBusy(true);

      try {
        switch (signInState) {
          case "signIn":
            // セッションの登録／変更
            // await invokeUpsertSession();

            // リロード
            // window.location.reload();
            break;
          case "signOut":
            // Auth情報の後始末
            setUser(undefined);

            // サインアウト確認非表示シグナル
            setShowConfirmSignOut(false);

            // 強制サインアウト非表示シグナル
            setShowRecommendSignOut(false);

            break;
        }
      } catch (error) {
        handleError(error, setFatal);
      } finally {
        setBusy(false);
      }
    },
    [setFatal]
  );

  // サインアウト時
  const onInvokeSignOut = useCallback(async () => {
    try {
      const session = await fetchAuthSession();

      if (session) {
        signOut();
      }
    } catch (error) {
      handleError(error, setFatal);

      // 既にログアウトされている(強制リロード)
      window.location.href = "/";
    }
  }, [setFatal, signOut]);

  // サインアウトボタンクリック時
  const onSignOutClick = useCallback(async () => {
    setShowConfirmSignOut(true);
  }, []);

  // サインアウトダイアログのクローズ時
  const onConfirmSignOutClose = useCallback(() => {
    setShowConfirmSignOut(false);
  }, []);

  // サインアウトダイアログのクローズボタンクリック時
  const onConfirmSignOutCloseClick = onConfirmSignOutClose;

  // サインアウト勧奨ダイアログのクローズ時
  const onRecommendSignOutClose = useCallback(() => {
    setShowRecommendSignOut(false);
  }, []);

  // サインアウト勧奨ダイアログのクローズボタンクリック時
  const onRecommendSignOutCloseClick = onRecommendSignOutClose;

  // セッション無効時
  const onSessionMissing = useCallback(() => setShowRecommendSignOut(true), []);

  // エラー表示のクローズ時
  const onCloseFatal = useCallback(() => {
    setFatalMessage(undefined);
    setForceLogoutMessage(undefined);
  }, []);

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }
  }, []);

  useEffect(() => {
    if (authUser) {
      getCredentialStates()
        .then((credentialStates) => {
          if (credentialStates === "NotAuthorized") {
            setFatal("Not authorized");
            signOut();
            setGroups(undefined);
            return;
          }

          if (credentialStates?.userInfo?.id) {
            setUserInfo(credentialStates?.userInfo);
          } else {
            signOut();
          }

          setGroups(credentialStates?.groups);
        })
        .catch((reason) => {
          window.console.error(reason);
        });
    }
  }, [signOut, authUser, setFatal]);

  useEffect(() => {
    onChangeSignInState(route);
    window.console.log(`route: ${route}`);
  }, [onChangeSignInState, route]);

  useEffect(() => {
    getUser(authUser?.username);
  }, [getUser, authUser?.username]);

  useEffect(() => {
    getCompany(user?.companyId);
  }, [getCompany, user?.companyId]);

  useEffect(() => {
    fetchAuthSession().catch(() => {
      if (authStatus === "authenticated") {
        onSessionMissing?.();
      }
    });
  }, [authStatus, location.pathname, onSessionMissing]);

  return {
    user,
    setUser,
    credentials,
    setCredentials,
    userInfo,
    setUserInfo,
    groups,
    setGroups,
    eventId,
    setEventId,

    company,
    setCompany,

    showConfirmSignOut,
    setShowConfirmSignOut,
    showRecommendSignOut,
    setShowRecommendSignOut,

    busy,
    setBusy,

    fatalMessage,
    forceLogoutMessage,
    setFatal,

    logoVisible,
    setLogoVisible,

    authUser,
    signOut,
    authStatus,
    route,

    location,

    isAuthenticated,
    isAgreed,
    isReadyToUse,

    showFatal,
    showForceLogout,

    trialExpired,

    getUser,
    getCompany,

    onChangeSignInState,
    onInvokeSignOut,

    onSignOutClick,
    onConfirmSignOutClose,
    onConfirmSignOutCloseClick,
    onRecommendSignOutClose,
    onRecommendSignOutCloseClick,
    onSessionMissing,
    onCloseFatal
  } as const;
};
